export const StyleUtils = {
  greenGradient: "radial-gradient(63.65% 55% at 50.51% 100%, rgba(10, 220, 182, 0.4) 0%, rgba(10, 220, 182, 0.1) 51.54%, rgba(10, 220, 182, 0) 100%), #142C2C",
  cardBackgroundGradient: "linear-gradient(180deg, #74E8E8 0%, rgba(116, 232, 232, 0) 100%)",
  roadmapBackgroundGradient: "linear-gradient(180deg,#74E8E8,#74E8E8,rgba(255,255,255,0.4),rgba(255,255,255,0.2))",
  activeIndicator: "linear-gradient(270deg, #74E8E8 0%, #FF2C2C 100%)",
  gridItemBackgroundGradient: (color: string) => `linear-gradient(151.06deg, #121212 50%, ${color} 256.32%)`,
  gridItemBorderGradient: (color1: string, color2: string) => `linear-gradient(180deg, ${color1} 0%, ${color2} 100%)`,
  maskGradient: "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
  scrollBottomGradient: "linear-gradient(#121212, rgba(255, 255, 255, 0.001))",
  scrollTopGradient: "linear-gradient(rgba(255, 255, 255, 0.001), #121212)",
  tableBackgroundGradient: "linear-gradient(0deg, rgba(24, 35, 35, 0.8), rgba(24, 35, 35, 0.8))",
  ctaButtonGradient: "radial-gradient(63.65% 55% at 50.51% 100%, rgba(10, 220, 182, 0.4) 0%, rgba(10, 220, 182, 0.1) 51.54%, rgba(10, 220, 182, 0) 100%), #142C2C",
  carouselGradient: "linear-gradient(80deg, #121212 10.92%, rgba(18, 18, 18, 0.00) 70.10%)",
  activeGradient: "radial-gradient(81.4% 70.34% at 50.51% 105.92%, rgba(15, 250, 207, 0.5) 0%, rgba(12, 236, 196, 0.125) 56.25%, rgba(10, 220, 182, 0) 100%),linear-gradient(0deg, #142C2C, #142C2C)",
  blueGradient: "linear-gradient(180deg, #469590 0%, #31D8D6 100%)",
  hoverTransition: (property?: string) => `${property ?? "all"} ease-in-out 0.2s`,
};
